'use client'

import ExtendStrings from '@repo/types/base/String'
import { Locale } from '@repo/types/user/User'
import { Button, type ButtonProps } from '@repo/ui/button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@repo/ui/dropdown-menu'
import { setLocale as setLocaleAction } from 'actions/auth'
import { useLocale, useTranslations } from 'next-intl'
import { useRouter } from 'next/navigation'
import { useCallback, useEffect, useMemo, useState, useTransition } from 'react'

export default function LocaleSelector({ variant = 'outline' }: Partial<Pick<ButtonProps, 'variant'>>) {
    const router = useRouter()
    const t = useTranslations('header')

    const locale = useLocale() as Locale

    const [isPending, startTransition] = useTransition()
    const setLocale = useCallback((l: Locale) => {
        startTransition(async () => {
            l = await setLocaleAction(l)
            if (locale !== l) router.refresh()
        })
    }, [locale, router])

    const upper = useMemo(() => {
        ExtendStrings()
        return locale.toUpperCase()
    }, [locale])

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button title={t('locale.select')} variant={variant} size='icon'>
                    {upper}
                    <span className='sr-only'>{t('locale.select')}</span>
                </Button>
            </DropdownMenuTrigger>

            <DropdownMenuContent align='end'>
                {
                    Locale.All.map(l => (
                        <DropdownMenuItem
                            disabled={isPending}
                            key={`select_locale_${l}`}
                            onClick={() => setLocale(l)}
                        >
                            {Locale.Native(l).capitalize(l)}
                        </DropdownMenuItem>
                    ))
                }
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
