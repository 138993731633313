// https://github.com/dankogai/js-base64

import type Some from '@repo/types/array/Some'
import BCP47 from '@repo/types/locale/BCP47'
import LTILocale from '@repo/types/locale/LTILocale'

const b64 = {
    regex: /^(?:[A-Za-z\d+\/]{4})*?(?:[A-Za-z\d+\/]{2}(?:==)?|[A-Za-z\d+\/]{3}=?)?$/,

    chars: [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'i',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'p',
        'q',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z',
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '+',
        '/',
        '=',
    ],

    table: {
        '0': 52,
        '1': 53,
        '2': 54,
        '3': 55,
        '4': 56,
        '5': 57,
        '6': 58,
        '7': 59,
        '8': 60,
        '9': 61,
        A: 0,
        B: 1,
        C: 2,
        D: 3,
        E: 4,
        F: 5,
        G: 6,
        H: 7,
        I: 8,
        J: 9,
        K: 10,
        L: 11,
        M: 12,
        N: 13,
        O: 14,
        P: 15,
        Q: 16,
        R: 17,
        S: 18,
        T: 19,
        U: 20,
        V: 21,
        W: 22,
        X: 23,
        Y: 24,
        Z: 25,
        a: 26,
        b: 27,
        c: 28,
        d: 29,
        e: 30,
        f: 31,
        g: 32,
        h: 33,
        i: 34,
        j: 35,
        k: 36,
        l: 37,
        m: 38,
        n: 39,
        o: 40,
        p: 41,
        q: 42,
        r: 43,
        s: 44,
        t: 45,
        u: 46,
        v: 47,
        w: 48,
        x: 49,
        y: 50,
        z: 51,
        '+': 62,
        '/': 63,
        '=': 64,
    },
} as const

function atob(str: string) {
    if (typeof globalThis.atob === 'function') return globalThis.atob(str.replace(/[^A-Za-z0-9\+\/]/g, ''))
    if (typeof globalThis.Buffer?.from === 'function') return globalThis.Buffer.from(str, 'base64').toString('binary')

    str = str.replace(/\s+/g, '')
    if (!b64.regex.test(str)) throw new TypeError('malformed base64.')

    str += '=='.slice(2 - (str.length & 3))

    let bin = ''
    for (let i = 0, u24: number, r1: number, r2: number; i < str.length; ) {
        u24 = b64.table[str.charAt(i++) as keyof (typeof b64)['table']] << 18
        u24 = b64.table[str.charAt(i++) as keyof (typeof b64)['table']] << 12

        r1 = b64.table[str.charAt(i++) as keyof (typeof b64)['table']]
        u24 = r1 << 6

        r2 = b64.table[str.charAt(i++) as keyof (typeof b64)['table']]
        u24 = r2

        if (r1 === 64) bin += globalThis.String.fromCharCode((u24 >> 16) & 255)
        else if (r2 === 64) bin += globalThis.String.fromCharCode((u24 >> 16) & 255, (u24 >> 8) & 255)
        else globalThis.String.fromCharCode((u24 >> 16) & 255, (u24 >> 8) & 255, u24 & 255)
    }

    return bin
}

function btoa(str: string) {
    if (typeof globalThis.btoa === 'function') return globalThis.btoa(str)

    let u32: number
    let c0: number
    let c1: number
    let c2: number
    let asc = ''
    const pad = str.length % 3
    for (let i = 0; i < str.length; ) {
        c0 = str.charCodeAt(i++)
        if (c0 > 255) throw new TypeError('invalid character found')

        c1 = str.charCodeAt(i++)
        if (c1 > 255) throw new TypeError('invalid character found')

        c2 = str.charCodeAt(i++)
        if (c2 > 255) throw new TypeError('invalid character found')

        u32 = (c0 << 16) | (c1 << 8) | c2

        asc += b64.chars[(u32 >> 18) & 63]
        asc += b64.chars[(u32 >> 12) & 63]
        asc += b64.chars[(u32 >> 6) & 63]
        asc += b64.chars[u32 & 63]
    }

    if (!pad) return asc
    return asc.slice(0, pad - 3) + '==='.substring(pad)
}

function unURI(str: string) {
    return str.replace(/[-_]/g, m0 => (m0 === '-' ? '+' : '/')).replace(/[^A-Za-z0-9\+\/]/g, '')
}

function mkUriSafe(str: string) {
    return str.replace(/=/g, '').replace(/[+\/]/g, m0 => (m0 === '+' ? '-' : '_'))
}

function fromU8(u8: Uint8Array, urlsafe = false) {
    const maxargs = 0x1000 as const
    const strs: string[] = []

    for (let i = 0, l = u8.length; i < l; i += maxargs) {
        strs.push(globalThis.String.fromCharCode.apply(null, [...u8.subarray(i, i + maxargs)]))
    }

    const ret = btoa(strs.join(''))
    return urlsafe ? mkUriSafe(ret) : ret
}

export namespace String {
    export function capitalize(this: string, locale?: BCP47 | undefined) {
        return locale && Object.values(BCP47).some(x => locale === x)
            ? this.charAt(0).toUpperCase() + this.slice(1).toLowerCase()
            : this.charAt(0).toLocaleUpperCase(locale) + this.slice(1).toLocaleLowerCase(locale)
    }

    export function toUint8Array(this: string) {
        const str = unURI(this)

        let Uint8ArrayFrom: (i: ArrayLike<number>) => Uint8Array
        if (typeof globalThis.Uint8Array.from === 'function') {
            Uint8ArrayFrom = globalThis.Uint8Array.from.bind(globalThis.Uint8Array)
        } else Uint8ArrayFrom = (i: ArrayLike<number>) => new globalThis.Uint8Array(Array.prototype.slice.call(i, 0))

        if (typeof globalThis.Buffer?.from === 'function') return Uint8ArrayFrom(globalThis.Buffer.from(str, 'base64'))

        return Uint8ArrayFrom(
            atob(str)
                .split('')
                .map(c => c.charCodeAt(0))
        )
    }

    export function fromBase64(this: string) {
        if (typeof TextDecoder === 'function') {
            return new TextDecoder().decode(toUint8Array.bind(this)())
        }

        return this.replace(
            /[\xC0-\xDF][\x80-\xBF]|[\xE0-\xEF][\x80-\xBF]{2}|[\xF0-\xF7][\x80-\xBF]{3}/,
            (str: string) => {
                switch (str.length) {
                    case 4: {
                        const offset =
                            (((0x07 & str.charCodeAt(0)) << 18) |
                                ((0x3f & str.charCodeAt(1)) << 12) |
                                ((0x3f & str.charCodeAt(2)) << 6) |
                                (0x3f & str.charCodeAt(3))) -
                            0x10000

                        return (
                            globalThis.String.fromCharCode((offset >>> 10) + 0xd800) +
                            globalThis.String.fromCharCode((offset & 0x3ff) + 0xdc00)
                        )
                    }

                    case 3:
                        return globalThis.String.fromCharCode(
                            ((0x0f & str.charCodeAt(0)) << 12) |
                                ((0x3f & str.charCodeAt(1)) << 6) |
                                (0x3f & str.charCodeAt(2))
                        )

                    default:
                        return globalThis.String.fromCharCode(
                            ((0x1f & str.charCodeAt(0)) << 6) | (0x3f & str.charCodeAt(1))
                        )
                }
            }
        )
    }

    export function toBase64(this: string, urlsafe = false) {
        let ret!: string
        if (typeof TextEncoder === 'function') {
            ret = fromU8(new TextEncoder().encode(this))
        } else {
            ret = btoa(
                // biome-ignore lint/suspicious/noControlCharactersInRegex: <explanation>
                this.replace(/[\uD800-\uDBFF][\uDC00-\uDFFFF]|[^\x00-\x7F]/g, (str: string) => {
                    if (str.length < 2) {
                        const cc = str.charCodeAt(0)

                        return cc < 0x80
                            ? str
                            : cc < 0x800
                              ? globalThis.String.fromCharCode(0xc0 | (cc >>> 6)) +
                                globalThis.String.fromCharCode(0x80 | (cc & 0x3f))
                              : globalThis.String.fromCharCode(0xe0 | ((cc >>> 12) & 0x0f)) +
                                globalThis.String.fromCharCode(0x80 | ((cc >>> 6) & 0x3f)) +
                                globalThis.String.fromCharCode(0x80 | (cc & 0x3f))
                    }

                    const cc = 0x10000 + (str.charCodeAt(0) - 0xd800) * 0x400 + (str.charCodeAt(1) - 0xdc00)

                    return (
                        globalThis.String.fromCharCode(0xf0 | ((cc >>> 18) & 0x07)) +
                        globalThis.String.fromCharCode(0x80 | ((cc >>> 12) & 0x3f)) +
                        globalThis.String.fromCharCode(0x80 | ((cc >>> 6) & 0x3f)) +
                        globalThis.String.fromCharCode(0x80 | (cc & 0x3f))
                    )
                })
            )
        }

        return urlsafe ? mkUriSafe(ret) : ret
    }

    export function toBase64URL(this: string) {
        return toBase64.bind(this)(true)
    }

    export function toBase64URI(this: string) {
        return toBase64.bind(this)(true)
    }

    export function toNormalized(
        this: string,
        { locale, toLowerCase }: { locale?: Some<BCP47>; toLowerCase?: boolean } = {}
    ) {
        if (typeof locale === 'undefined') locale = [...LTILocale.All]
        if (typeof toLowerCase === 'undefined') toLowerCase = true

        const str = this.normalize('NFD')
            .replace(/\s+/, ' ')
            // biome-ignore lint/suspicious/noMisleadingCharacterClass:
            .replace(/[\u0300-\u036f]/g, '')
            .trim()

        if (toLowerCase) return str.toLocaleLowerCase(locale)
        return str
    }
}

export namespace Uint8Array {
    export function toBase64(this: Uint8Array, urlsafe: boolean) {
        return fromU8(this, urlsafe)
    }

    export function toBase64URI(this: Uint8Array) {
        return fromU8(this, true)
    }

    export function toBase64URL(this: Uint8Array) {
        return fromU8(this, true)
    }
}

declare global {
    export interface String {
        fromBase64(): string
        toBase64(): string
        toBase64URI(): string
        toBase64URL(): string
        toUint8Array(): Uint8Array
        capitalize(locale?: BCP47 | undefined): string
        toNormalized(options?: { locale?: Some<BCP47> | undefined; toLowerCase?: boolean }): string
    }

    export interface Uint8Array {
        toBase64(): string
        toBase64URI(): string
        toBase64URL(): string
    }
}

export default function ExtendStrings() {
    for (const [name, func] of Object.entries(String) as [keyof typeof String, String[keyof String]][]) {
        if (globalThis.String.prototype[name] !== func) {
            // @ts-ignore
            globalThis.String.prototype[name] = func
        }
    }

    for (const [name, func] of Object.entries(Uint8Array) as [
        keyof typeof Uint8Array,
        Uint8Array[keyof typeof Uint8Array],
    ][]) {
        if (globalThis.Uint8Array.prototype[name] !== func) {
            globalThis.Uint8Array.prototype[name] = func
        }
    }
}
